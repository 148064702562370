import Vue from 'vue'
import VueRouter from 'vue-router'
import Mapuzzle from "../views/Mapuzzle";
import MapuzzleSelect from "../views/tizuru/Select";
import MapuzzlePlay from "../views/tizuru/Play";
import MapuzzleDesc from '../views/tizuru/Description'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/mapuzzle'
  },
  {
    path: '/mapuzzle',
    component: Mapuzzle,
    children: [
      {
        path: '/mapuzzle',
        component: MapuzzleDesc
      },
      {
        path: '/mapuzzle/select',
        component: MapuzzleSelect
      },
      {
        path: '/mapuzzle/play',
        component: MapuzzlePlay
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
