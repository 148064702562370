<template>
  <v-container>
    <v-row class="justify-center">
      <v-col v-for="mode in modes" :key="mode.href" cols="auto" md="4">
        <v-card style="height: 100%">
          <router-link :to="mode.href" style="text-decoration-color: royalblue;color: black">
            <v-img
                :src="mode.img"
                class="black--text align-end"
                gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,.2)"
            >
            </v-img>
            <v-card-title> {{ mode.name }} </v-card-title>
          </router-link>
          <v-divider/>
          <v-card-text style="text-align: left">
            <h3>せつめい</h3>
            <p
              class="my-1 py-1"
              v-for="text in mode.desc"
              :key="text"
              v-text="text"
            ></p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import singleImg from "../../assets/PuzzleDetail.png";

export default {
  name: "Description",
  data() {
    return {
      modes: [
        {
          name: "シングルプレイ",
          href: "/mapuzzle/select",
          desc: [
            "すきな場所の地図でパズルをしよう！",
            "ふつうモードと地図るProのためのサドンデスモード！"],
          img: singleImg,
          isDisable: false
        },
        {
          name: "マルチプレイ(まだない)",
          href: "/mapuzzle/select",
          desc: ["ゲーム画面のURLを共有してみんなで楽しめるかも？"],
          img: singleImg,
          isDisable: true
        }
      ],
    }
  }
}
</script>

<style scoped>

</style>
