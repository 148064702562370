import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isStarted: false,
    isCleared: false,
    isGameOver: false,
    clearTime: 0,
    mapStatus: {
      zoom: 10,
      center: [35.6812, 139.7665],
      pcs: '1',
    },
    pcsList: [
      '12',
      '48',
      '192',
      '300',
      '1200'],
  },
  getters: {
    getCleared: state => {
      return state.isCleared
    },
    getGameOver: state => {
      return state.isGameOver
    },
    getStarted: state => {
      return state.isStarted
    },
    getClearTime: state => {
      return state.clearTime
    },
    getMapStatus: state => {
      return state.mapStatus
    },
    getPcsList: state => {
      return state.pcsList
    },
  },
  mutations: {
    invertCleared (state) {
      state.isCleared = !state.isCleared
    },
    invertStarted (state) {
      state.isStarted = !state.isStarted
    },
    invertGameOver (state) {
      state.isGameOver = !state.isGameOver
    },
    setClearTime (state, time) {
      state.clearTime = time
    },
    setMapStatus (state, status) {
      state.mapStatus = status
    },
  },
  actions: {
    gameStart (context) {
      context.commit('invertStarted')
    },
    gameClear (context) {
      context.commit('invertCleared')
    },
    gameOver (context) {
      context.commit('invertGameOver')
    },
    clearTime (context, time) {
      context.commit('setClearTime', time)
    },
    updateMapStatus (context, status) {
      context.commit('setMapStatus', status)
    },
    revertMapStatus (context) {
      const status = {
        zoom: 10,
        center: [35.6812, 139.7665],
        pcs: '1',
      }
      context.commit('setMapStatus', status)
    }
  },
  plugins: [createPersistedState()],
})
